.game_status{
border:2px solid red;
font-size: larger;
font-weight: bold;
text-align: center;
background: red;
color: white;
padding: 4px 5px;
}
/* Add this CSS to your Sixcard.css or any other CSS file */
.game_form_container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 72vh;
  }
  
  .game_form {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Optional: Add a shadow effect */
    max-width: 400px; /* Optional: Limit the form's width */
  }
  
  .game_form label,
  .game_form input {
    display: block;
    margin-bottom: 10px;
  }
  
  .game_form label {
    font-size: 16px;
    font-weight: bold;
  }
  
  .game_form input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .game_form input:focus {
    border-color: #007bff; /* Optional: Add a different border color on focus */
    outline: none;
  }
  
  /* Optional: Add some styles to the submit button */
  .game_form button {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .game_form button:hover {
    background-color: #0056b3;
  }
  
  .inputSix_wrapper{
    /* position: relative;
    top:55px; */
    border:"2px solid red";
    z-index:2;
  }
 .incoming_card_image{
    height: 100%;
    width: 200px;
    border: solid black;

 }
 .inVissible_el {
  opacity: 0;
  visibility: hidden;
}