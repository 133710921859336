.Toastify__toast-container--top-center {
    top: 50% !important;
    transform: translate(-50%, -50%);
    width: 100% !important;
    max-width: 350px !important;
}

.Toastify__toast-container--top-center .Toastify__toast-body {
    padding: 30px;
    font-weight: 600;
    color:white;
}
.Toastify__toast-theme--dark{
    background: #ffe9e9 !important;
}
.Toastify__toast-container--top-center .Toastify__toast-body{
    font-size: 25px !important;
    color: #000 !important;
}