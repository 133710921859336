.board {
    /* display: flex; */
    align-items: center;
    box-sizing: content-box;
    /* aspect-ratio: 2/3; */
    max-width: 178px;
    height: 285px;
    /* height: 100%; */

  }
  img, svg{
    max-width: 100%;
  }
  .board,
  .cards,
  .wrapper,
  .content {
    transform-style: preserve-3d;
  }
  
article.board.reset-card div:last-child .flipped{
  transform: rotateY(0deg) !important;
}
.col-md-6.computer_cad .row.justify-content-end {
  transform: rotateY(0deg);
}
  .cards {
    --duration: 1200ms;
    position: absolute;
    width: 100%;
    outline: none;
    border: none;
    cursor: pointer;
    padding: 0;
    background-color: transparent;
    transition: all 200ms;
    pointer-events: none;
    top: 0;
    height: 100%;
    transition: transform 0.6s ease;
  }
  .cards:nth-child(1) {
    transform: translateZ(3px);
  }
  .cards:nth-child(2) {
    transform: translateZ(6px);
  }
  .cards:nth-child(3) {
    transform: translateZ(9px);
  }
  .cards:nth-child(4) {
    transform: translateZ(12px);
  }
  .cards:nth-child(5) {
    transform: translateZ(15px);
  }
  .cards:nth-child(6) {
    transform: translateZ(18px);
  }
  .cards:nth-child(7) {
    transform: translateZ(21px);
  }
  .cards:nth-child(8) {
    transform: translateZ(24px);
  }
  .cards:nth-child(10) {
    transform: translateZ(27px);
  }
  .cards:nth-child(11) {
    transform: translateZ(30px);
  }
  .cards:nth-child(12) {
    transform: translateZ(33px);
  }
  .cards:nth-child(13) {
    transform: translateZ(36px);
  }
  .cards:nth-child(14) {
    transform: translateZ(39px);
  }
  .cards:nth-child(15) {
    transform: translateZ(42px);
  }
  .cards:nth-child(16) {
    transform: translateZ(45px);
  }
  .cards:nth-child(17) {
    transform: translateZ(48px);
  }
  .cards:nth-child(18) {
    transform: translateZ(51px);
  }
  .cards:nth-child(19) {
    transform: translateZ(54px);
  }
  .cards:nth-child(20) {
    transform: translateZ(57px);
  }
  .cards:nth-child(21) {
    transform: translateZ(60px);
  }
  .cards:nth-child(22) {
    transform: translateZ(63px);
  }
  .cards:nth-child(23) {
    transform: translateZ(66px);
  }
  .cards:nth-child(24) {
    transform: translateZ(69px);
  }
  .cards:nth-child(25) {
    transform: translateZ(72px);
  }
  .cards:nth-child(26) {
    transform: translateZ(75px);
  }
  .cards:nth-child(27) {
    transform: translateZ(79px);
  }
  .cards:nth-child(28) {
    transform: translateZ(82px);
  }
  .cards:nth-child(29) {
    transform: translateZ(85px);
  }
  .cards:nth-child(30) {
    transform: translateZ(88px);
  }
  .cards:nth-child(31) {
    transform: translateZ(91px);
  }
  .cards:nth-child(32) {
    transform: translateZ(94px);
  }
  .cards:nth-child(33) {
    transform: translateZ(97px);
  }
  .cards:nth-child(34) {
    transform: translateZ(100px);
  }
  .cards:nth-child(35) {
    transform: translateZ(103px);
  }
  .cards:nth-child(36) {
    transform: translateZ(106px);
  }
  .cards:nth-child(37) {
    transform: translateZ(109px);
  }
 
 /* computer cards */
 .computer_card:nth-child(1) {
  transform: translateZ(-3px);
}
.computer_card:nth-child(2) {
  transform: translateZ(-6px);
}
.computer_card:nth-child(3) {
  transform: translateZ(-9px);
}
.computer_card:nth-child(4) {
  transform: translateZ(-12px);
}
.computer_card:nth-child(5) {
  transform: translateZ(-15px);
}
.computer_card:nth-child(6) {
  transform: translateZ(-18px);
}
.computer_card:nth-child(7) {
  transform: translateZ(-21px);
}
.computer_card:nth-child(8) {
  transform: translateZ(-24px);
}
.computer_card:nth-child(10) {
  transform: translateZ(-27px);
}
.computer_card:nth-child(11) {
  transform: translateZ(-30px);
}
.computer_card:nth-child(12) {
  transform: translateZ(-33px);
}
.computer_card:nth-child(13) {
  transform: translateZ(-36px);
}
.computer_card:nth-child(14) {
  transform: translateZ(-39px);
}
.computer_card:nth-child(15) {
  transform: translateZ(-42px);
}
.computer_card:nth-child(16) {
  transform: translateZ(-45px);
}
.computer_card:nth-child(17) {
  transform: translateZ(-48px);
}
.computer_card:nth-child(18) {
  transform: translateZ(-51px);
}
.computer_card:nth-child(19) {
  transform: translateZ(-54px);
}
.computer_card:nth-child(20) {
  transform: translateZ(-57px);
}
.computer_card:nth-child(21) {
  transform: translateZ(-60px);
}
.computer_card:nth-child(22) {
  transform: translateZ(-63px);
}
.computer_card:nth-child(23) {
  transform: translateZ(-66px);
}
.computer_card:nth-child(24) {
  transform: translateZ(-69px);
}
.computer_card:nth-child(25) {
  transform: translateZ(-72px);
}
.computer_card:nth-child(26) {
  transform: translateZ(-75px);
}
.computer_card:nth-child(27) {
  transform: translateZ(-79px);
}
.computer_card:nth-child(28) {
  transform: translateZ(-82px);
}
.computer_card:nth-child(29) {
  transform: translateZ(-85px);
}
.computer_card:nth-child(30) {
  transform: translateZ(-88px);
}
.computer_card:nth-child(31) {
  transform: translateZ(-91px);
}
.computer_card:nth-child(32) {
  transform: translateZ(-94px);
}
.computer_card:nth-child(33) {
  transform: translateZ(-97px);
}
.computer_card:nth-child(34) {
  transform: translateZ(-100px);
}
.computer_card:nth-child(35) {
  transform: translateZ(-103px);
}
.computer_card:nth-child(36) {
  transform: translateZ(-106px);
}
.computer_card:nth-child(37) {
  transform: translateZ(-109px);
}
  
  .main_battle .face img{
    height: 100% !important;
    object-fit: unset;
  }
  .inline_buttons ul li button {
    background: #14954e;
    border-radius: 5px;
  padding: 10px;
}
  .wrapper {
    pointer-events: initial;
    display: block;
    position: relative;
    height: 100%;
    transition: all var(--duration) ease-out;
    transform-origin: calc( 100% + 10px ) 50%;
  }
  
  .content {
    display: block;
    height: 100%;
    
  }
  
  .face {
    transition: transform calc(var(--duration) * 3 / 4);
    transition-delay: calc(var(--duration) / 6);
    position: absolute;
    inset: 0;
    backface-visibility: hidden;
    background-size: cover;
    background-position: center;
    background-color: white;
  }
  
  .front {
    transform: rotateZ(0.5turn) rotateY(0.5turn);
    
  }
  .flipped .front{
    border-width: 3px;
    border-color: black;
    border-style: solid;
  }
  
  /* .cards.flipped:nth-child(1) {
    transform: translateZ(9px);
  }
  .cards.flipped:nth-child(2) {
    transform: translateZ(6px);
  }
  .cards.flipped:nth-child(3) {
    transform: translateZ(3px);
  } */
  .cards.flipped .wrapper {
    transform: rotateY(0.5turn);
  }
  /* .cards.flipped .content {
    transform: rotateX(-0.5turn) rotateY(1.5turn);
  } */
  .computer_card.flipped .content {
    transform: rotateX(-0.5turn) rotateY(-1.5turn);
  }
  .computer_card .wrapper {
    transform-origin: calc( 0% - 10px ) 50%;
}
  .computer_card.flipped .wrapper{
    transform: rotateY(-0.5turn);
  }
  .flipped,.flipped *{
    pointer-events: none;
  }

  .flipped {
    transform: rotateY(180deg);
  }
  /* .Result_wrapper_duration {
    margin-left: -72px;
} */

span#After_load_outer img {
  transform: rotateX(-0.5turn) rotateY(1.5turn);
}